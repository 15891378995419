export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}
export function slugify(str) {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '')
}

export function triggerResize() {
  window.dispatchEvent(new Event('resize'))
}

export function setUtmParams(url) {
  if (typeof window === "undefined") {
    return url
  }

  const newUrl = new URL(url)
  const currentParams = new URLSearchParams(window.location.search)

  currentParams.forEach((value, key) => {
    newUrl.searchParams.append(key, value)
  })

  return newUrl.href
}